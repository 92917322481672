.settings-modal-body div {
    margin-bottom: 30px;
}

.modal-header {
    background-color: #40414F;
    color: wheat;
}

.modal-body {
    background-color: #40414F;
}

.modal-body .change-API-button {
    background-color: #b6251e;
    border: none;
    margin-left: auto;
    margin-right: auto;
    display: flex;
}

.modal-body .change-API-button:hover {
    background-color: #b6251e;
    border: none;
}

.settings-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.settings-content {
    margin-bottom: 20px;
}

.modal-footer .close-button {
    background-color: #03a33e;
    border: none;
}

.modal-footer .close-button:hover {
    background-color: #03a33e;
    border: none;
}
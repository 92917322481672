@font-face {
    font-family: userMsgFont;
    src: url('../../TestSöhne-Buch.otf');
}

@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');

.chat-container {
    display: flex;
    flex-direction: column;
    height: 93vh;
    /* max-width: 800px; */
    width: 1120px;
    margin-left: auto;
    margin-right: auto;
    /* margin-bottom: 300px; */
    /* margin-bottom: 100; */
    padding: 20px;
    /* overflow: hidden; */
    /* resize: none; */
}

.api-input-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* background-color: red; */
    height: 87vh;
}

.api-input-form .submit-button {
    /* padding-left: 10px;
    padding-right: 10px; */
    border: none;
    margin-top: 4px;
    width: 85px;
    height: 30px;
    font-size: medium;
    background-color: #03a33e;
    color: #fff;
    border-radius: 4px;
    cursor: pointer;
}

.input-center {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    /* background-color: #03a33e; */
}

.input-center input:focus {
    outline: none;
}

.input-center h2 {
    color: wheat;
}

.input-center h4 {
    color: wheat;
    font-size: large;
    margin-bottom: 40px;
}

.input-center input {
    margin-bottom: 16px;
    padding: 10px;
    width: 300px;
    color: #FFFFFF;
    background-color: #40414F;
    border: 1px solid #40414F;
    border-radius: 4px;
}

.input-center input::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #8E8EA0;
    /* opacity: 1; */
    /* Firefox */
}


.avatarImage {
    border-radius: 500px;
    height: 50px;
    width: 50px;
    margin-bottom: 20px;
    background-color: #40414F;
    border: 1px solid #40414F;
}

img {
    height: 200px;
    width: 350px;
}

.messages {
    flex-grow: 1;
    overflow-y: scroll;
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
    /* overflow: hidden; */
    /* resize: none; */
}

.messages::-webkit-scrollbar {
    width: 6px;
}

.messages::-webkit-scrollbar-thumb {
    background-color: transparent;
}

.messages::-webkit-scrollbar-track {
    background-color: transparent;
}

a {
    color: #C9C9CF;
    font-weight: 560;
    /* Set the desired color for the links */
}

.message {
    background-color: #f0f0f0;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 10px;
    width: 1024px;
    /* white-space: break-spaces; */
}

.message:last-child {
    margin-bottom: 10;
}

.message.user-message {
    background-color: #343541;
    color: #C9C9CF;
    font-size: medium;
    font-family: userMsgFont;
}

.message.bot-message {
    background-color: #444654;
    color: #D1D5DB;
    font-size: medium;
    font-family: 'Inter', sans-serif;
}

.copy-button {
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    color: #999;
    font-family: 'Inter', sans-serif;
}

.copy-button p {
    color: #D1D5DB;
}

.input-form {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    margin-top: 20px;
    /* rest of the styles */
}

.button-group {
    display: flex;
    gap: 20px;
}

.button-group button {
    padding: 8px 16px;
    border: none;
    /* rest of the button styles */
}

.input-form textarea[type="text"] {
    flex-grow: 1;
    padding: 20px;
    width: 650px;
    color: #FFFFFF;
    background-color: #40414F;
    border: 1px solid #40414F;
    border-radius: 14px;
    font-weight: 340;
    overflow: hidden;
    resize: none;
    font-size: medium;
    font-family: userMsgFont;
}

::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #8E8EA0;
    /* opacity: 1; */
    /* Firefox */
}

.input-form button {
    padding: 8px 16px;
    border: none;
    margin-top: 4px;
    width: 75px;
    height: 75px;
    background-color: #03a33e;
    color: #fff;
    border-radius: 4px;
    cursor: pointer;
}

.input-form button:disabled {
    background-color: #686868;
    cursor: not-allowed;
}


/* Styles for mobile phones (up to 400px) */
@media (max-width: 440px) {
    .chat-container {
        width: 100%;
        padding: 10px;
    }

    .message {
        background-color: #f0f0f0;
        border-radius: 8px;
        padding: 20px;
        margin-bottom: 10px;
        width: 100%;
    }

    .message:last-child {
        margin-bottom: 10;
    }


    .input-center input {
        width: 100%;
    }

    .input-form {
        flex-direction: column;
        gap: 10px;
        margin-bottom: -50px;
    }

    .input-form textarea[type="text"] {
        width: 100%;
        font-size: small;
        border-radius: 8px;
    }

    .input-form button {
        padding: 8px 16px;
        border: none;
        margin-top: 4px;
        width: 63px;
        height: 63px;
        background-color: #03a33e;
        color: #fff;
        border-radius: 4px;
        cursor: pointer;
    }
}